import Vue from 'vue';
import VueRouter from 'vue-router';
import LayoutMenuTopoConteudo from '../views/layouts/LayoutMenuTopoConteudo.vue';
import LayoutTopoConteudo from '../views/layouts/LayoutTopoConteudo.vue';
import LayoutVazio from '../views/layouts/LayoutVazio.vue';
import _404 from '../views/404.vue';
import store from '../store/index'
import storage from '../store/modules/storage'
import axios from 'axios';
import lodash from 'lodash';

var LAYOUTS = {
  LayoutMenuTopoConteudo,
  LayoutTopoConteudo,
  LayoutVazio
}

Vue.use(VueRouter);

var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [],
});

var rotasEssenciais = [];


// Método utilizado no beforeEnter das rotas.
// Ele que decide se vai pode acessar ou não
export const somenteAutenticado = (to, from, next) => {

  if (window && window.ReactNativeWebView) {
    window?.ReactNativeWebView?.postMessage(to?.path);
  };

  if (storage.getToken()) {
    next();
  } else {
    let rota = rotasEssenciais.filter(item => item.meta.isLogin);
    if (rota.length && rota[0].name) {
      next({
        name: rota[0].name
      });
    } else {
      next({
        name: 'rota-nao-definida'
      });
      console.error("[ROUTER] Falha ao registrar uma rota de login (nenhuma rota com 'isLogin' foi definida) ");
    }
  }
}

// Método utilizado no beforeEnter das rotas.
// Ele que decide se vai pode acessar ou não
export const somenteNaoAutenticado = (to, from, next) => {

  if (window && window.ReactNativeWebView) {
    window?.ReactNativeWebView?.postMessage(to?.path);
  };
  if (storage.getToken()) {
    let rota = rotasEssenciais.filter(item => item.meta.isHome);
    if (rota.length && rota[0].name) {
      next({
        name: rota[0].name
      });
    } else {
      next({
        name: 'rota-nao-definida'
      });

      console.error("[ROUTER] Falha ao registrar uma rota home (nenhuma rota com 'isHome' foi definida) ");
    }
  } else {
    next();
  }

  /*
  if  (!storage.getUserObj()) {
    next();
  } else {
    let rota = router.getRoutes().filter(item => item.meta.isHome);
    if  (rota.length && rota[0].name) {
      next({
        name : rota[0].name
      });
    } else {
      next({
        name : 'rota-nao-definida'
      });
      console.error("[ROUTER] Falha ao registrar uma rota home (nenhuma rota com 'isHome' foi definida) ");
    }
  }
  */
  registerModule(to)
}

function registrarRotaPadrao() {
  //Usado para redirecionar qualquer requisição não registradaz
  let rotaDefault = router.getRoutes().filter(item => item.meta.isLogin);
  if (rotaDefault.length && rotaDefault[0].name) {
    router.addRoute({
      path: '*',
      redirect: {
        name: rotaDefault[0].name
      },
    });
  } else {
    console.error("[ROUTER] / Default - Falha ao registrar uma rota de login (nenhuma rota com 'isLogin' foi definida) ");
  }
}

function registrarRota404() {
  router.addRoute({
    path: '/404',
    name: 'rota-nao-definida',
    meta: {
      id: 'nao-definido'
    },
    component: _404
  });
}



getRoutes()

function getRoutes() {
  if (process.env.VUE_APP_SUBDOMINIO) {
    Vue.prototype.$host = {
      hostname: window.location.host,
      protocolo: window.location.protocol,
      subdominio: process.env.VUE_APP_SUBDOMINIO
    }
  } else {
    Vue.prototype.$host = {
      hostname: window.location.host,
      protocolo: window.location.protocol,
      subdominio: window.location.host.split('.')[0]
    }
  }

  registrarRota404();

  function registraRotasEssenciais(dadosFront) {
    let rotas = dadosFront.estrutura.filter((rota) => rota.props.isLogin || rota.props.isHome);

    for(let rotaEssencial of rotas){
      rotasEssenciais.push({
        name: rotaEssencial.id,
        meta: rotaEssencial.props
      })
    }
  }

  const api = `${process.env.VUE_APP_BACKEND_URL}/api/frontend?subdominio=${Vue.prototype.$host.subdominio}&situacao=A`

  //axios.get(api).then(retorno => {
  storage.getFrontend(Vue.prototype.$host.subdominio).then(dadosFront => {

    //let dadosFront = lodash.get(retorno, 'data.dados');

    if (dadosFront && dadosFront.estrutura) {

      document.title = dadosFront.titulo || 'Sem título';

      let link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = `${process.env.VUE_APP_BUCKET_URL}/${Vue.prototype.$host.subdominio}/imagens/identidade/favicon.png`;
      document.getElementsByTagName('head')[0].appendChild(link);

      registerModule({ name: 'mainFramework' });
      registerModule({ name: 'demo' });
      registerModule({ name: 'clicCodeStudio' });

      registraRotasEssenciais(dadosFront)

      dadosFront.estrutura.forEach(dadosRota => {
        let rota = {
          ...dadosRota,
          name: dadosRota.id,
          path: dadosRota.props.path,
          component: LAYOUTS[dadosRota.component],
          meta: {
            childrens: dadosRota.childrens,
            events: dadosRota.events,
            isLogin: dadosRota.props.isLogin,
            isHome: dadosRota.props.isHome,
            title: stringToObject(dadosRota.props.title),
            subtitle: stringToObject(dadosRota.props.subtitle)
          },
          beforeRouteEnter: dadosRota.props.authenticated ? somenteAutenticado : somenteNaoAutenticado,
          beforeEnter: dadosRota.props.authenticated ? somenteAutenticado : somenteNaoAutenticado,
        }
        delete rota.childrens;
        router.addRoute(rota);

      });

      registrarRotaPadrao();

      //Estrutura de menus
      if (dadosFront.menus) {
        Vue.prototype.$menus = dadosFront.menus;
      }

      //Estrutura navbar
      if (dadosFront.navbar) {
        Vue.prototype.$navbar = dadosFront.navbar;
      }

      // Estrutura scripts
      if (dadosFront.scripts) {
        Vue.prototype.$scripts = dadosFront.scripts;
      }
    }
  })
}

function stringToObject(string) {
  try {
    return JSON.parse(string);
  } catch (_) {
    return string;
  }
};

function registerModule(to) {
  if (!store.hasModule(to.name)) {
    store.registerModule(`${to.name}`, {
      namespaced: true,
      state() {
        return {
          propriedades: {},
        }
      },
      getters: {
        getPropriedades(state) {
          return state.propriedades
        },
      },
      mutations: {
        setPropriedades(state, propriedades) {
          if (propriedades) {
            state.propriedades = { ...state.propriedades, ...propriedades };
          }

        },
      },
    })
  }
}

export default router;
