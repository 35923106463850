<template>
  <ClicColInterno
    alignSelf="end"
    :cp="cp"
    :key="componentKey"
    containerStyle="height: 100%;padding-left:0px;padding-right:0px;"
    
  >
    <div :id="mongoDbChartId" style="width: 100%; height: 100%; padding: 0px;"></div>
  </ClicColInterno>
</template>
<script>

import ComponentMixin from '../../core/ComponentMixin';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import storage from '../../store/modules/storage';

export default {
  name: 'ClicMongoDBChart',
  mixins: [ComponentMixin],
  data() {
    return {

      public : {
        dataRefresh : this.dataRefresh
      },

      mongoDbChartId : this.$utils.getComponentID()

    };
  },

  mounted(){
  },
  
  methods: {

    dataRefresh(parametros) {
      let parametrosChartsEmbedSDK = {
        baseUrl: this.baseUrl,
        chartId: this.chartId,
        dashboardId: this.dashboardId,
        ...parametros
      };

      const sdk = new ChartsEmbedSDK({
        ...parametrosChartsEmbedSDK
      });

      if (this.type == "dashboard") {
        this.loadDashboard(sdk, parametrosChartsEmbedSDK);

      } else if (this.type == "chart") {
        this.loadChart(sdk, parametrosChartsEmbedSDK);

      } else {
        console.error(`[ClicMongoDBChart] Falha carregar 'dashboard'. Detalhes: ${e}`);
      }
    },


    loadDashboard(sdk, parametros) {
      const dashboard = sdk.createDashboard({
        getUserToken : () => {
          return storage.getToken()
        },
        showAttribution: false,
        widthMode: "scale",
        
        width: '100%',

        theme: "dark",
        background: "transparent",


        filter: {
          "situacao.descricao": { $in : ["Faturado", "Cancelado"] }
        },

        ...parametros
      });
      dashboard
        .render(document.getElementById(this.mongoDbChartId))
        .catch((e) => {
          console.error(`[ClicMongoDBChart] Falha ao carregar 'dashboard'. Detalhes: ${e}`);
        });
    },

    loadChart(sdk, parametros) {
      const chart = sdk.createChart({
        getUserToken : () => {
          return storage.getToken()
        },
        showAttribution: false,
        ...parametros
      });
      chart
        .render(document.getElementById(this.mongoDbChartId))
        .catch((e) => {
          console.error(`[ClicMongoDBChart] Falha ao carregar 'chart'. Detalhes: ${e}`);
        });
    }


  },
};
</script>


<style>
  .Charts_app-dashboard-shared_layout---mtgxT {
    padding:0px !important;
  }
</style>