<template>
    <ClicColInterno :cp="cp">
        <div class="form-group left-label">
            <label class="form-label">
                {{ $storage.getTextoTraduzido(label) }}
            </label>
            <div class="form-control-wrap">
                <b-form-checkbox v-model="valueModelSwitch" style="height: 1.806rem;" switch :disabled="disabled"
                    :class="this.class" :style="style" @change="change" @input="input">
                </b-form-checkbox>
            </div>
        </div>
    </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name: 'ClicSwitch',
    mixins: [ComponentMixin],

    data() {
        return {
            public: {
                isValid: this.validateField,
                clearField: this.clearField,

                undo: this.undo,
                apply: this.apply,
                isValueChanged: this.isValueChanged,
            },

            dictionaryValue: [],
            valueModelSwitch: this.cp.props.value || false,
            value: this.cp.props.value || false,
            oldValue: this.cp.props.value || false,

        }
    },
    watch: {
        valueModelSwitch(newValue) {
            this.definirValue(newValue)
        },
        value(newValue) {
            this.definirSwitch(newValue)

            this.definirValue(newValue)
            
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.oldValue = this.value;
            this.dictionaryValue = {
                "true": this.trueValue || true,
                "false": this.falseValue || false,
                "undefined": this.falseValue || false,
            }

            this.dictionaryModelValue = {
                [this.trueValue || "true"]: true,
                [this.falseValue || "false"]: false,
                false: false,
                true: true,
                undefined: false

            }

            this.valueModelSwitch = this.dictionaryModelValue[this.value] || false;
            this.value = this.dictionaryValue[this.valueModelSwitch.toString()];
        })

    },


    methods: {

        definirValue(newValue){
            try{

            
            const newValueStr = newValue?.toString() || "undefined";

            if (this.valuePrecisaSerAtualizado(newValueStr) && this.novoValorExisteNoDicionario(newValueStr)) {
                this.value = this.dictionaryValue[newValueStr];
            }
        }catch(erro){
            console.log(`ClicSwitch - Erro ao atribuir valor ao value. Detalhes ${erro.toString()}`)
        }
        },

        definirSwitch(newValue){
            try{

                if (this.switchPrecisaSerAtualizado(newValue)) {
                    this.valueModelSwitch = this.dictionaryModelValue[newValue];
                }
            }catch(erro){
                console.log(`ClicSwitch - Erro ao atribuir valor ao switch. Detalhes ${erro.toString()}`)
            }
        },

        switchPrecisaSerAtualizado(newValue){
            return this.valueModelSwitch !== this.dictionaryModelValue[newValue]
        },

        valuePrecisaSerAtualizado(newValueStr){
            return this.value !== this.dictionaryValue[newValueStr]
        },

        novoValorExisteNoDicionario(newValueStr){
            return this.dictionaryValue[newValueStr] != undefined
        },


        change(checked) {
            if (this.lodash.has(this.cp, 'events.change')) {
                try {
                    eval(this.cp.events.change);
                } catch (e) {
                    console.error(`[ClicSwitch] Falha ao executar evendo 'change'. Detalhes: ${e}`);
                }
            }
        },

        input(checked) {
            if (this.lodash.has(this.cp, 'events.input')) {
                try {
                    eval(this.cp.events.input);
                } catch (e) {
                    console.error(`[ClicSwitch] Falha ao executar evendo 'input'. Detalhes: ${e}`);
                }
            }
        }
    },
};
</script>