<template>
  <div class="not-found">

    <div class="not-found-content">

      <div class="img-container">
        <img class="logo-clic" src="https://storage.googleapis.com/download/storage/v1/b/cv-pub-cloud/o/admfw%2Fimagens%2Fidentidade%2Flogo.png?generation=1709291760749147&alt=media">
      </div>
      <p class="not-found-message">Oops! A página que você está procurando não foi encontrada.</p>
      <router-link to="/" class="not-found-link">Voltar para a página inicial</router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NaoDefinido',
  props: {
    id:{
      type: String,
      default: "",
    },
    label:{
      type: String,
      default: "",
    },
    cp:{
      default: "",
    }
  }
};
</script>


<style scoped>
.not-found {
  justify-content: center;
  padding: 10%;
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  color: #343a40;
  text-align: center;
}

.img-container{
  width: 100%;
  justify-content: center;
  display: flex
;
}

.logo-clic{
  height: 75px;
}

.not-found-content {
  max-width: 600px;
}

.not-found-title {
  font-size: 8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.not-found-message {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;
}

.not-found-link {
  display: inline-block;
    padding: 0.75rem 1.5rem;
    color: #000000;
    text-decoration: underline;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s;
}

</style>